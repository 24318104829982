
import Navigation from './Components/Navigation'




function App() {
  console.log("Hello Developers!")
  return (
    <div
     style={{
      background:" #1D2228",
        overflow:"hidden ",
      }}
    className="App">
      <Navigation />
   
    </div>
  );
}

export default App;
